import axios from "axios";
// import { getAdminToken, getSessionId, getUserToken } from "@/store/session";
// import router from "@/router";

axios.defaults.timeout = 30000;
// axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";
console.log(axios, "axios");
// HTTP拦截器
axios.interceptors.request.use(
  (config) => {
    // set token
    config.headers["Chat-Token"] = "";
    config.headers["Authorization"] = "";
    config.headers["Admin-Authorization"] = "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    let data = response.data;

    if (data.code === 0) {
      return response;
    } else {
      return Promise.reject(response.data);
    }
  },
  (error) => {
    // if (error.response && error.response.status === 401) {
    //   // 获取axios路径
    //   const path = error.config.url;
    //   if (path.indexOf("/admin") !== -1) {
    //     router.push("/pcadmin/login");
    //   } else {
    //     let url = document.location.toString();
    //     let arrUrl = url.split("//");
    //     if (arrUrl[1].indexOf("share") <= 0) {
    //       router.push("/login");
    //     }
    //   }
    // }
    return Promise.reject(error);
  }
);

// send a http get request
export function httpGet(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// send a http post request
export function httpPost(url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// send a http put request
export function httpPut(url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// send a http delete request
export function httpDelete(url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, data, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
